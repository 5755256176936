import React from 'react'
import Layout from '../../staticcomponents/layout'
import ShortBlogHeader from './shortblogheader'
import CategorySocialMedia from '../../staticcomponents/category-social media'
import AuthorDetails from '../../staticcomponents/author details'
import './shortblog.scss'


export default function Shortblog() {
    return (
        <>
            <Layout>
                <div className='ShortBlog_main'>
                    <div className='bg-donuts-right'>
                        <ShortBlogHeader />
                        <CategorySocialMedia />
                        <AuthorDetails />
                
                        {/* <div className='bg-capsule'></div>*/}
                    </div>
                </div>
            </Layout>
        </>
    )
}