import React from 'react'
import { Grid } from "@mui/material";
import hero_image from '../../../assets/Hero image.png';
import green_arrow from '../../../assets/long blog arrow.png';
import blog_image from '../../../assets/Long Blog 3.png';
import './ShortBlogHeader.scss'
import DownloadbtnText from '../../../staticcomponents/downloadbtn-text'
import Container from '@material-ui/core/Container';

export default function ShortBlogHeader() {
  return (
    <>
      <Container maxWidth="xl" className='short-blog-container-cls'>
        <Grid container className="short-blog-header-cls">
          {/* **********************    Text-with-Image-row    ********************** */}
          <Grid container className="Text-with-hero-img">
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <div className="header-text">
                    <div className="header-text-div">
                      <p className="header-date">
                         BLOGS / 22 JUN 2022
                      </p>
                      <h1 className="header-title">
                      rising inflation and the importance of cost of living allowance
                      </h1>
                        <p className="author-name">
                           Daisy Jennett
                        </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12} className="header-heroimg">
                  <img src={hero_image} alt="hero image" />
                </Grid>
              </Grid>
        
          {/* **********************   Text-row-2    ********************** */}
 <Grid container className="rich-text-para-padding">
            <Grid item xl={0} lg={0} md={2} sm={2} xs={0}></Grid>
              <Grid item xl={12} lg={12} md={10} sm={10} xs={12} className="section-with-blue-div">
            <Grid container item xl={6} lg={6} md={12} sm={12} xs={12} className="text-with-blue-div">
              <Grid className="text-with-blue-section" item xl={8} lg={9} md={7} sm={12} xs={12}>
                <img src={green_arrow} alt="green arrow" className="green-arrow" />
                <div className="date">SURVEYS / APRIL 2022</div>
                <div className="title">CORE/FLEX PULSE SURVEY</div>
                <p className="description">If there’s one thing the global pandemic has taught us, it’s that flexibility is key to success. A core/flex policy approach is becoming the number one trend in relocation for a reason: it provides ultimate flexibility to companies and employees.</p>

              </Grid>
            </Grid>
           
            <Grid container item xl={6} lg={6} md={12} sm={12} xs={12}>
                 <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="rich-text-para">
                <p>
                  Many regions and countries are experiencing record-high inflation, and although some regions, like Asia, are faring well, many more are not. Take, for instance, my home country of Lebanon, which is clocking shocking hikes to the tune of over 200% (and still rising!), making its capital, Beirut, one of the most expensive places to work abroad. If, like me, you’re based in the U.S., I’m sure you’ve felt the 7% inflation rate at the grocery store, gas pumps, and in other parts of daily life.
                </p>
                <p>
                  Many regions and countries are experiencing record-high inflation, and although some regions, like Asia, are faring well, many more are not. Take, for instance, my home country of Lebanon, which is clocking shocking hikes to the tune of over 200% (and still rising!), making its capital, Beirut, one of the most expensive places to work abroad. If, like me, you’re based in the U.S., I’m sure you’ve felt the 7% inflation rate at the grocery store, gas pumps, and in other parts of daily life.
                </p>
                <p>
                  Many regions and countries are experiencing record-high inflation, and although some regions, like Asia, are faring well, many more are not. Take, for instance, my home country of Lebanon, which is clocking shocking hikes to the tune of over 200% (and still rising!), making its capital, Beirut, one of the most expensive places to work abroad. If, like me, you’re based in the U.S., I’m sure you’ve felt the 7% inflation rate at the grocery store, gas pumps, and in other parts of daily life.
                </p>
              </Grid>
            </Grid>
          </Grid>
          </Grid>
          {/* **********************   Text-row-3    ********************** */}

          <Grid container className="image-with-topic-padding">
              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="image-with-topic">
               <Grid item xl={6} lg={6} md={11} sm={11} xs={12} className="left-image">
              <img src={blog_image} alt="blog_image" />
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12} >
              <div className="right-image">
                <img src={hero_image} alt="hero image" />
              </div>
              </Grid>
              </Grid>
            </Grid>
          
        </Grid>
      </Container>
    </>
  )
}


